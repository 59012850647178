import { graphql } from "gatsby"
import React from "react"
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope"
import { FaGithub } from "@react-icons/all-files/fa/FaGithub"
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe"
import { FaMapMarker } from "@react-icons/all-files/fa/FaMapMarker"
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter"

import Layout from "../components/layout"
import Posts from "../components/posts"
import SEO from "../components/seo"
import "./blog-author.scss"

const blogAuthorTemplate = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const author = data.authorsYaml
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={author.name}
        description={author.summary || author.description}
      />

      <h2 className="blog-author-name">{author.name}</h2>

      {(!!author.location || !!author.social) &&
        <div className="blog-author-info">
          {!!author.location &&
            <small className="blog-author-location"><FaMapMarker /> {author.location}</small>
          }
          {!!author.social &&
            <div className="blog-author-social">
              {!!author.social.email &&
                <small className="blog-author-social-email">
                  <a href={`mailto:${author.social.email}`}>
                    <FaEnvelope /> {author.social.email}
                  </a>
                </small>
              }
              {!!author.social.website &&
                <small className="blog-author-social-website">
                  <a href={author.social.website}>
                    <FaGlobe /> {author.social.website}
                  </a>
                </small>
              }
              {!!author.social.twitter &&
                <small className="blog-author-social-twitter">
                  <a href={`https://twitter.com/${author.social.twitter}`}>
                    <FaTwitter /> @{author.social.twitter}
                  </a>
                </small>
              }
              {!!author.social.github &&
                <small className="blog-author-social-github">
                  <a href={`https://github.com/${author.social.github}`}>
                    <FaGithub /> {author.social.github}
                  </a>
                </small>
              }
            </div>
          }
        </div>
      }

      {!!author.description &&
        <p className="blog-author-description">{author.description}</p>
      }

      {(posts.length === 0) &&
        <div>No posts of this author</div>
      }

      {(posts.length > 0) &&
        <div className="blog-author-posts">
          <Posts posts={posts} />
        </div>
      }
    </Layout>
  )
}

export default blogAuthorTemplate

export const pageQuery = graphql`
  query BlogAuthorById(
    $id: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    authorsYaml(id: { eq: $id }) {
      name
      summary
      description
      location
      social {
        email
        github
        twitter
        website
      }
    }
    allMarkdownRemark(filter: {frontmatter: {authors: {elemMatch: {id: { eq: $id }}}}}, sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          authors {
            name
            fields {
              slug
            }
          }
        }
      }
    }
  }
`
